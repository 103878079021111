/* body, */
/* #root, */
/* #root>div  */
html{
  height: 100%;
}

body {
  margin: 0;
  font-family: "Rubik", "sans-serif";
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Rubik", "sans-serif";
  /* source-code-pro, Menlo, Monaco, Consolas, "Courier New", */
  /* monospace; */
}

.rtl {
  direction: rtl;
}

.rtl .MuiInputLabel-outlined {
  transform: translate(-14px, 20px) scale(1) !important;
}

.rtl .MuiInputLabel-shrink {
  transform-origin: top right !important;
  transform: translate(-14px, -6px) scale(0.75) !important;
}

.rtl .MuiInputLabel-formControl {
  left: unset !important;
  right: 0 !important;
}

.rtl .MuiOutlinedInput-notchedOutline legend {
  text-align: right !important;
}

.rtl .react-tel-input {
  direction: ltr;
}

.rtl .MuiSelect-iconOutlined {
  right: unset !important;
  left: 0 !important;
}

.rtl .MuiOutlinedInput-input {
  left: unset !important;
  right: 0 !important;
}

.MuiMenuItem-gutters {
  justify-content: flex-end !important;
}

#birthday {
  text-align: right !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: -15px;
}